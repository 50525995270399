import React from "react";
import { Link, useLocation } from "react-router-dom";
import DataTable from "../../Components/DataTable/DataTable";
import Navbar from "../../Components/Navbar/Navbar";
import Sidebar from "../../Components/Sidebar/Sidebar";
import TaskList from "../../Components/TaskList/TaskList";
import "../UserLists/userlists.scss";

function Task() {
  const location = useLocation(); // Get the location object
  const { type } = location.state || {};

  return (
    <div className="list_page">
      <div className="home_sidebar">
        <Sidebar />
      </div>

      <div className="list_page_main">
        <Navbar />

        {/* mui data table */}
        <div className="data_table">
          <div className="btnn">
            <Link
              to={`/tasks/addnew`}
              style={{ textDecoration: "none" }}
            >
              <button type="button">Add New {type}</button>
            </Link>
          </div>
            <TaskList type={type} />
        </div>
      </div>
    </div>
  );
}

export default Task;
