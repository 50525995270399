import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./datatable.scss";

function DataTable({ type }) {
  const [data, setData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null); // Anchor element for menu
  const [selectedRow, setSelectedRow] = useState(null); // Store selected row
  const [open, setOpen] = useState(false); // State to manage dialog visibility

  const [error, setError] = useState(false); // Track whether to show the error popup
  const [errorMessage, setErrorMessage] = useState("");

  // Fetch access token from session storage
  const token = sessionStorage.getItem("token");
  let userType;
  if (type === "user") {
    userType = "ALL";
  } else if (type === "watcher") {
    userType = "WATCHER";
  } else if (type === "creator") {
    userType = "CREATOR";
  } else if (type === "expert") {
    userType = "EXPERT";
  }

  // Define an async function to fetch all data from the single API
  const fetchData = async () => {
    const API_URL = `https://api.dev.civilianmarkhorpakistan.com/admin/all-users?page=1&pageSize=100&userType=${userType}`; // Replace with your actual API URL

    try {
      const response = await fetch(API_URL, {
        headers: {
          Authorization: `${token}`, // Pass the access token from session
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const result = await response.json();
      setData(result.data.response); // Set data in state
    } catch (error) {
      setErrorMessage(error.message);
      setError(true); // Show the error popup
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleMenuClick = (event, row) => {
    setAnchorEl(event.currentTarget); // Set the anchor for the menu
    setSelectedRow(row); // Set the current selected row
  };

  const handleMenuClose = () => {
    setAnchorEl(null); // Close the menu
  };

  // Function to open the dialog
  const handleOpen = () => {
    setOpen(true); // Open dialog
    handleMenuClose(); // Close menu when dialog opens
  };

  // Function to close the dialog
  const handleClose = () => {
    setOpen(false);
  };

  // Handle verification API call with selected row's ID
  const handleVerification = async () => {
    if (!selectedRow?.id) return; // Ensure selectedRow is valid before proceeding

    const API_URL_PaymentVerify =
      "https://api.dev.civilianmarkhorpakistan.com/admin/user-status"; // Replace with your actual API URL

    try {
      const response = await fetch(API_URL_PaymentVerify, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`, // Pass the access token from session
        },
        body: JSON.stringify({
          userId: selectedRow.id, // Use the selected row's ID
          isPaymentVerified: true,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to verify payment");
      }

      const data = await response.json();
      console.log("Payment verification successful:", data);

      fetchData(); // Refresh the data
      setOpen(false); // Close the dialog after successful verification
    } catch (error) {
      setErrorMessage(error.message);
      setError(true);
    }
  };

  const columns = [
    {
      field: "fullName",
      headerName: "FullName",
      width: 150,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
    },
    { field: "phoneNumber", headerName: "PhoneNumber", width: 180 },
    {
      field: "status",
      headerName: "Status",
      width: 100,
    },
    {
      field: "isPaymentVerified",
      headerName: "PaymentStatus",
      width: 150,
    },
    {
      field: "paymentReceiptURL",
      headerName: "Payment Receipt",
      width: 150,
    },
    {
      field: "isEmailVerified",
      headerName: "Verified",
      width: 140,
    },
    { field: "userType", headerName: "UserType", width: 120 },
    {
      field: "action",
      headerName: "Action",
      width: 170,
      renderCell: (params) => (
        <div className="actionn">
          <Link to={params.row.id}>
            <button type="button" className="view_btn">
              View
            </button>
          </Link>
          {/* Three-dot menu */}
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={(e) => handleMenuClick(e, params.row)}
          >
            <MoreVertIcon />
          </IconButton>

          {/* Menu for Update and Delete */}
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl && selectedRow?.id === params.row.id)}
            onClose={handleMenuClose}
          >
            <MenuItem
              onClick={handleOpen} // Open dialog when clicking "Verify Payment"
              sx={{
                backgroundColor: "#f5f5f5",
                color: "#3f51b5",
                "&:hover": {
                  backgroundColor: "#3f51b5",
                  color: "#fff",
                },
                borderRadius: "4px",
                fontWeight: "bold",
                fontSize: "14px",
              }}
            >
              Verify Payment
            </MenuItem>
            {/* Dialog (Popup) */}
            <Dialog
              open={open}
              onClose={handleClose}
              BackdropProps={{
                style: { backgroundColor: "rgba(0, 0, 0, 0.1)" },
              }} // Transparent background
              PaperProps={{
                style: {
                  padding: "20px",
                  borderRadius: "10px",
                  textAlign: "center", // Center all text
                },
              }}
            >
              {/* Centered and styled title */}
              <DialogTitle
                sx={{
                  color: "#00796b", // Dark teal color for heading (green tone)
                  textAlign: "center", // Center heading
                  fontWeight: "bold",
                }}
              >
                Verify Payment
              </DialogTitle>

              {/* Centered and styled content */}
              <DialogContent>
                <DialogContentText
                  sx={{
                    color: "#1e88e5", // Blue color for the text
                    textAlign: "center", // Center text
                    fontSize: "16px",
                  }}
                >
                  Are you sure you want to verify this payment?
                </DialogContentText>
              </DialogContent>

              {/* Styled action buttons */}
              <DialogActions sx={{ justifyContent: "center" }}>
                {/* "Yes" button in green */}
                <Button
                  onClick={handleVerification}
                  sx={{
                    marginRight: 4,
                    backgroundColor: "#4caf50", // Green color for "Yes"
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#43a047", // Darker green on hover
                    },
                  }}
                  autoFocus
                >
                  Yes
                </Button>
                {/* "No" button in red */}
                <Button
                  onClick={handleClose}
                  sx={{
                    backgroundColor: "#e53935", // Red color for "No"
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#d32f2f", // Darker red on hover
                    },
                  }}
                >
                  No
                </Button>
              </DialogActions>
            </Dialog>
          </Menu>
        </div>
      ),
    },
  ];

  return (
    <div className="data_table" >
      <DataGrid
        className="data_grid"
        rows={data}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
      />
    </div>
  );
}

export default DataTable;
