/* eslint-disable no-constant-condition */
/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import ErrorPopup from '../Error/ErrorPopup'; // Import the ErrorPopup component
import "./Login.scss";

const API_URL = 'https://api.dev.civilianmarkhorpakistan.com/admin/login'; // Replace with your actual API URL

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false); // Track whether to show the error popup
  const [errorMessage, setErrorMessage] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        throw new Error('Invalid credentials');
      }

      const data = await response.json();

      // Assuming the API returns user data and a token
      sessionStorage.setItem('user', JSON.stringify(data)); // Store user data
      
      sessionStorage.setItem('token', data.accessToken); // Store token if available

      navigate('/'); // Redirect to home/dashboard
    } catch (error) {
      setErrorMessage(error.message);
      setError(true); // Show the error popup
    }
  };

  const handleClosePopup = () => {
    setError(false); // Close the popup
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2 className="login-title">Login</h2>
        <form onSubmit={handleLogin}>
          <div className="input-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="login-btn">
            Login
          </button>
        </form>
      </div>

      {error && <ErrorPopup message={errorMessage} onClose={handleClosePopup} />} {/* Show error popup */}
    </div>
  );
}

export default Login;
