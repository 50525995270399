import React from "react";
import "./ErrorPopup.scss"; // Ensure you have a corresponding SCSS file

const ErrorPopup = ({ message, onClose }) => {
  return (
    <div className="popup-overlay">
      <div className="popup-card">
        <button className="close-btn" onClick={onClose}>
          &times; {/* Cross icon */}
        </button>
        <h4 className="popup-message">{message}</h4>
      </div>
    </div>
  );
};

export default ErrorPopup;
