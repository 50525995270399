import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import React from "react";
import { Link } from "react-router-dom";
import "./itemlists.scss";

function ItemLists({ type, data }) {
  let itemData;

  // Dynamically change the UI content based on the type
  switch (type) {
    case "user":
      itemData = {
        title: "USERS",
        isMoney: false,
        count: data.userCount || 0,
        icon: (
          <PermIdentityIcon
            style={{
              color: "#FF74B1",
              backgroundColor: "#FFD6EC",
            }}
            className="icon"
          />
        ),
        link: "See all users",
        linkto: "/users",
      };
      break;
    case "watcher":
      itemData = {
        title: "WATCHERS",
        isMoney: false,
        count: data.userCountWatcher || 0,
        icon: (
          <PermIdentityIcon
            style={{
              color: "#FF74B1",
              backgroundColor: "#FFD6EC",
            }}
            className="icon"
          />
        ),
        link: "See all Watchers",
        linkto: "/users",
      };
      break;
    case "creator":
      itemData = {
        title: "CREATORS",
        isMoney: false,
        count: data.userCountCreator || 0,
        icon: (
          <AttachMoneyOutlinedIcon
            style={{
              color: "#367E18",
              backgroundColor: "#A7FFE4",
            }}
            className="icon"
          />
        ),
        link: "See all Creator",
        linkto: "/users",
      };
      break;
    case "expert":
      itemData = {
        title: "EXPERTS",
        isMoney: false,
        count: data.userCountExpert || 0,
        icon: (
          <PaidOutlinedIcon
            style={{
              color: "#AC7088",
              backgroundColor: "#B1B2FF",
            }}
            className="icon"
          />
        ),
        link: "See all Experts",
        linkto: "/users",
      };
      break;
    default:
      break;
  }

  return (
    <div className="item_listss">
      <div className="name">
        <p>{itemData.title}</p>
        <span className="percentage positive">
          <KeyboardArrowUpIcon />
          20 %
        </span>
      </div>

      <div className="counts">
        {itemData.isMoney && <AttachMoneyOutlinedIcon />}
        {itemData.count}
      </div>

      <div className="see_item">
        <Link to={itemData.linkto} state={{ type }}>
          <p>{itemData.link}</p>
        </Link>
        {itemData.icon}
      </div>
    </div>
  );
}

export default ItemLists;
