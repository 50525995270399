import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ColorContext } from "../../ColorContext/darkContext";

// import sass file
import "./navbar.scss";

// import images
import admin from "../../Images/admin_pic.jpg";

function Navbar({ isSticky }) {
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);
  const { darkMode, dispatch } = useContext(ColorContext);

  let data = sessionStorage.getItem("user"); // Store user data
  let { data: user } = JSON.parse(data);
  console.log(user);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/login");
  };

  return (
    <div className={`navbar ${isSticky ? "sticky" : ""}`}>
      <div className="navbar_main">
        <div className="menu_logo">
          {toggle ? (
            <CloseIcon className="menu_icon" onClick={handleToggle} />
          ) : (
            <MenuIcon className="menu_icon" onClick={handleToggle} />
          )}

          <Link to="/" style={{ textDecoration: "none" }}>
            <h3 className="text_none">Dashboard</h3>
          </Link>
        </div>
        <div className="search">
          <input type="text" placeholder="Search.." />
          <SearchIcon className="search_icon" />
        </div>

        <div className="item_lists">
          <div className="item item_lan">
            <p>{user.fullName}</p>
          </div>
          <div className="item">
            <img className="admin_pic" src={admin} alt="admin" />
          </div>
        </div>
      </div>

      <div className="res_navbar">
        {toggle && (
          <div className="res_nav_menu">{/* Responsive menu code here */}</div>
        )}
      </div>
    </div>
  );
}

export default Navbar;
