import React, { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import Sidebar from "../../Components/Sidebar/Sidebar";
import "./AddNewTask.scss";

function AddNewTask({ titlee }) {
  // State for form data
  const [taskInp, setTaskInp] = useState({
    taskTitle: "",
    taskPrice: 0,
    service: "", // Platform (selected from dropdown)
    imageUrl: "",
    taskUrl: "",
    description: "", // Optional
    assignedTo: "WATCHER", // Default value
  });

  // States for loading, error, success
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [platforms, setPlatforms] = useState([]); // To store fetched platform data
  const [imageUploading, setImageUploading] = useState(false); // Track image upload state

  // Fetch platforms when the component mounts
  useEffect(() => {
    const fetchPlatforms = async () => {
      try {
        const response = await fetch(
          "https://api.dev.civilianmarkhorpakistan.com/service"
        );
        if (response.ok) {
          const data = await response.json();
          setPlatforms(data.data); // Access the 'data' property of the response
        } else {
          throw new Error("Failed to fetch platforms");
        }
      } catch (err) {
        setError("An error occurred while fetching the platforms.");
        console.error("Error fetching platforms:", err);
      }
    };

    fetchPlatforms();
  }, []); // Empty dependency array to run only once on mount

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;

    // If taskPrice, convert to a number
    if (name === "taskPrice") {
      setTaskInp({ ...taskInp, [name]: Number(value) });
    } else {
      setTaskInp({ ...taskInp, [name]: value });
    }
  };

  // Handle image upload
  // Handle image upload
  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("file", file);

    setImageUploading(true);

    try {
      const response = await fetch(
        "https://api.dev.civilianmarkhorpakistan.com/auth/upload-file",
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        const data = await response.json();
        const imageUrl = data.data.url; // Access the URL from the response
        setTaskInp({ ...taskInp, imageUrl: imageUrl }); // Set the image URL in taskInp
        setSuccess("Image uploaded successfully.");
      } else {
        throw new Error("Image upload failed.");
      }
    } catch (err) {
      setError("Error uploading the image.");
      console.error("Image upload error:", err);
    } finally {
      setImageUploading(false);
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    setSuccess("");

    try {
      const token = sessionStorage.getItem("token");

      // Find the ObjectId for the selected service
      const selectedPlatform = platforms.find(
        (platform) => platform.title === taskInp.service
      );

      if (selectedPlatform) {
        const serviceObjectId = selectedPlatform._id; // Assuming platforms have an _id field

        // Update taskInp to include the ObjectId for service
        const updatedTaskInp = { ...taskInp, service: serviceObjectId };

        const response = await fetch(
          "https://api.dev.civilianmarkhorpakistan.com/task",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              accept: "*/*",
              Authorization: token,
            },
            body: JSON.stringify(updatedTaskInp), // Send the updated task data
          }
        );

        if (response.ok) {
          const responseData = await response.json();
          setSuccess("Task added successfully!");
        } else {
          const errorData = await response.json();
          setError(errorData.message || "Failed to add the task");
        }
      } else {
        setError("Selected platform not found.");
      }
    } catch (err) {
      setError("An error occurred while adding the task.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="add_new_task">
      <Sidebar />
      <div className="new_task_page">
        <Navbar />
        <div className="new_task_content">
          <h1>{titlee}</h1>
          <form onSubmit={handleSubmit} className="form">
            <div className="form_grid">
              {/* Task Title */}
              <div className="form_group">
                <label>Task Title</label>
                <input
                  type="text"
                  name="taskTitle"
                  value={taskInp.taskTitle}
                  onChange={handleChange}
                  placeholder="Enter task title"
                  required
                />
              </div>

              {/* Task Price */}
              <div className="form_group">
                <label>Task Price</label>
                <input
                  type="number"
                  name="taskPrice"
                  value={taskInp.taskPrice}
                  onChange={handleChange}
                  placeholder="Enter task price"
                  required
                />
              </div>

              {/* Platform (Service) */}
              <div className="form_group">
                <label>Platform</label>
                <select
                  name="service"
                  value={taskInp.service}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Platform</option>
                  {platforms.map((platform, index) => (
                    <option key={index} value={platform.title}>
                      {platform.title}
                    </option>
                  ))}
                </select>
              </div>

              {/* Image Upload */}
              <div className="form_group">
                <label>Upload Image</label>
                <input
                  type="file"
                  name="image"
                  onChange={handleImageUpload}
                  disabled={imageUploading || loading}
                />
                {imageUploading && <p>Uploading image...</p>}
                {taskInp.imageUrl && (
                  <p>Image uploaded successfully: {taskInp.imageUrl}</p>
                )}
              </div>

              {/* Task URL */}
              <div className="form_group">
                <label>Task URL</label>
                <input
                  type="text"
                  name="taskUrl"
                  value={taskInp.taskUrl}
                  onChange={handleChange}
                  placeholder="Enter task URL"
                  required
                />
              </div>

              {/* Description (Optional) */}
              <div className="form_group">
                <label>Description (Optional)</label>
                <textarea
                  name="description"
                  value={taskInp.description}
                  onChange={handleChange}
                  placeholder="Enter task description (optional)"
                />
              </div>

              {/* Assigned to */}
              <div className="form_group">
                <label>Assigned To</label>
                <select
                  name="assignedTo"
                  value={taskInp.assignedTo}
                  onChange={handleChange}
                  required
                >
                  <option value="WATCHER">Watcher</option>
                  <option value="EXPERT">Expert</option>
                  <option value="CREATOR">Creator</option>
                </select>
              </div>
            </div>

            {/* Loading and error messages */}
            {loading && <p className="loading_message">Submitting...</p>}
            {error && <p className="error_message">{error}</p>}
            {success && <p className="success_message">{success}</p>}

            <button
              type="submit"
              className="submit_btn"
              disabled={loading || !taskInp.imageUrl}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddNewTask;