import React, { useState } from "react";
import Input from "../../Components/Input/Input";
import Navbar from "../../Components/Navbar/Navbar";
import Sidebar from "../../Components/Sidebar/Sidebar";
import "./AddNewUser.scss";

function AddNew({ inputs, titlee, type }) {
  let dynamicInpVal;
  console.log("TTTTTTTTTT", type);

  // Dynamically change the state values based on the type
  switch (type) {
    case "USER":
      dynamicInpVal = {
        username: "",
        name: "",
        email: "",
        password: "",
        address: "",
      };
      break;
    case "SERVICE":
      dynamicInpVal = {
        title: "",
        description: "",
        imageUrl: "",
        thumbnailUrl: "",
      };
      break;
    case "BLOG":
      dynamicInpVal = {
        title: "",
        description: "",
        tags: "",
      };
      break;
    default:
      break;
  }

  const [userInp, setUserInp] = useState(dynamicInpVal);
  const [file, setFile] = useState("");
  const [loading, setLoading] = useState(false); // For loading state
  const [error, setError] = useState(""); // For error handling
  const [success, setSuccess] = useState(""); // For success handling

  const image = false;

  // Dynamically change the data for different pages
  const handleChange = (e) => {
    setUserInp({ ...userInp, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    setSuccess("");

    try {
      const token = sessionStorage.getItem("token");

      // POST request to the server with the form data
      const response = await fetch("https://api.dev.civilianmarkhorpakistan.com/service", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(userInp), // Send the form data as JSON
      });

      if (response.ok) {
        const responseData = await response.json();
        setSuccess("Form submitted successfully!");
        console.log("Server response:", responseData);
      } else {
        throw new Error("Failed to submit the form");
      }
    } catch (err) {
      setError("An error occurred while submitting the form.");
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="add_new">
      <div className="home_sidebar">
        <Sidebar />
      </div>

      <div className="new_page">
        <Navbar />

        <div className="new_page_main">
          <div className="new_page_content">
            <form onSubmit={handleSubmit} className="form">
              {inputs.map((detail) => (
                <Input
                  key={detail.id}
                  {...detail}
                  value={userInp[detail.name]}
                  onChange={handleChange}
                />
              ))}
              <div className="form_inp">
                <label>Description</label>
                <textarea
                  type="text"
                  name="description"
                  value={userInp.description}
                  onChange={handleChange}
                />
              </div>

              {loading && <p>Submitting...</p>}
              {error && <p style={{ color: "red" }}>{error}</p>}
              {success && <p style={{ color: "green" }}>{success}</p>}

              <button type="submit" className="submit_btn" disabled={loading}>
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddNew;