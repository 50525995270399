import { default as React, useEffect, useState } from "react";
import Chart from "../Chart/Chart";
import ItemLists from "../ItemLists/ItemLists";
import Navbar from "../Navbar/Navbar";
import ProgressBar from "../ProgressBar/ProgressBar";
import Sidebar from "../Sidebar/Sidebar";
import TableList from "../TableList/TableList";

import "./Home.scss";

function Home() {
  const [data, setData] = useState({}); // State to store API data
  // Fetch access token from session storage
  const token = sessionStorage.getItem("token");

  // Define an async function to fetch all data from the single API
  const fetchData = async () => {
    const API_URL = "https://api.dev.civilianmarkhorpakistan.com/admin/dashboard"; // Replace with your actual API URL

    try {
      const response = await fetch(API_URL, {
        headers: {
          Authorization: `${token}`, // Pass the access token from session
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const result = await response.json();
      setData(result.data); // Set data in state
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };
  useEffect(() => {
    // if (type === "user") {
    fetchData();
    // }
  }, []); // Empty dependency array ensures this runs only once

  return (
    <div className="home">
      <div className="home_sidebar">
        <Sidebar />
      </div>

      <div className="home_main">
        {/* Pass isSticky to Navbar */}
        <Navbar isSticky={true} />

        <div className="bg_color" />

        <div className="home_items">
          <ItemLists type="user" data={data} />
          <ItemLists type="watcher" data={data} />
          <ItemLists type="creator" data={data} />
          <ItemLists type="expert" data={data} />
        </div>

        <div className="chart_sec">
          <ProgressBar />
          <Chart height={450} title="Revenue" />
        </div>

        <div className="table">
          <div className="title">Latest Transactions</div>
          <TableList />
        </div>
      </div>
    </div>
  );
}

export default Home;
