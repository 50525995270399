import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BarChartIcon from "@mui/icons-material/BarChart";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import TableChartIcon from "@mui/icons-material/TableChart";
import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

import { ColorContext } from "../../ColorContext/darkContext";
import "./Sidebar.scss";

function Sidebar() {
  const navigate = useNavigate();

  // color state management using react context
  const { darkMode, dispatch } = useContext(ColorContext);

  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/login");
  };

  return (
    <div className="sidebar">
      <div className="logo">
        <Link to="/" style={{ textDecoration: "none" }}>
          <h3 className="text_none">AdminDashboard</h3>
        </Link>
      </div>

      <div className="links">
        <ul>
          <p className="spann">Main</p>
          <Link to="/" style={{ textDecoration: "none" }}>
            <li>
              <DashboardIcon className="icon" /> Dashboard
            </li>
          </Link>

          <p className="spann">lists</p>
          <Link
            to="/users"
            state={{ type: "user" }}
            style={{ textDecoration: "none" }}
          >
            <li>
              <PersonIcon className="icon" /> Users
            </li>
          </Link>

          {/* <Link
            to="/services"
            state={{ type: "service" }}
            style={{ textDecoration: "none" }}
          >
            <li>
              <TableChartIcon className="icon" /> Services
            </li>
          </Link> */}
          <Link to="/orders" style={{ textDecoration: "none" }}>
            <li>
              <CreditCardIcon className="icon" /> Orders
            </li>
          </Link>
          <Link to="/addTask" state={{ type: "task" }} style={{ textDecoration: "none" }}>
            <li>
              <CreditCardIcon className="icon" /> Tasks
            </li>
          </Link>
          <li>
            <CreditCardIcon className="icon" /> Balance
          </li>
          <li>
            <BarChartIcon className="icon" /> Status
          </li>

          <p className="spann">Seetings</p>
          <li>
            <AccountCircleIcon className="icon" /> Profile
          </li>
          <li>
            <SettingsRoundedIcon className="icon" /> Setting
          </li>
          <li onClick={handleLogout} style={{ cursor: "pointer" }}>
            <LogoutIcon className="icon" /> Log Out
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Sidebar;
