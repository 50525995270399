import React, { useState, useEffect } from "react";
import "./tableList.scss";

// MUI table imports
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

function TableList() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      const API_URL = "https://api.dev.civilianmarkhorpakistan.com/orders";
      const token = sessionStorage.getItem("token");

      if (!token) {
        setError("Authentication token is missing.");
        setLoading(false);
        return;
      }

      try {
        setLoading(true);

        const response = await fetch(API_URL, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Assuming "Bearer" prefix is required
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        console.log(result,"dsfdsfdsfdsfdsfsdfdsfsdfdsf");
        
        setData(result.data); // Assuming `data` is the key containing the orders
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <TableContainer component={Paper} className="table_list">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="table_cell">Tracking ID</TableCell>
            <TableCell className="table_cell">Name</TableCell>
            <TableCell className="table_cell">Email</TableCell>
            <TableCell className="table_cell">Service</TableCell>
            <TableCell className="table_cell">Status</TableCell>
            <TableCell className="table_cell">Number</TableCell>
            <TableCell className="table_cell">Created At</TableCell>
            <TableCell className="table_cell">Reference URL</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row._id}>
              <TableCell className="table_cell">{row._id}</TableCell>
              <TableCell className="table_cell">{row.name}</TableCell>
              <TableCell className="table_cell">{row.email}</TableCell>
              <TableCell className="table_cell">{row.service?.title}</TableCell>
              <TableCell className="table_cell">{row.status}</TableCell> 
              <TableCell className="table_cell">{row.whatsappNumber}</TableCell>
              <TableCell className="table_cell">
                {new Date(row.createdAt).toLocaleDateString()}
              </TableCell>
              <TableCell className="table_cell">
                <a
                  href={row.referenceUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {row.referenceUrl}
                </a>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TableList;
