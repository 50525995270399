import { useContext } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute"; // Import the ProtectedRoute component

import "./app.scss";
import { ColorContext } from "./ColorContext/darkContext";
import Home from "./Components/Home/Home";
import Orders from "./Components/Orders/Orders";
import AddNew from "./Pages/AddNew/AddNew";
import BlogDetail from "./Pages/BlogDetail/BlogDetail";
import Blogs from "./Pages/Blogs/Blogs";
import Detail from "./Pages/Detail/Detail";
import Login from "./Pages/Login/Login";
import UpdateUser from "./Pages/UpdateUser/UpdateUser";
import Lists from "./Pages/UserLists/UserLists";
import Task from "./Pages/Tasks/Task";
// import AddNewTask from "./Pages/AddNewTask/AddNewTask";
import AddNewUser from './Pages/AddNewUser/AddNewUser'
// Dynamicaly change the data for different pages(replaceable)
const userInpDetails = [
  {
    id: 2,
    name: "username",
    lable: "Username",
    type: "text",
    placeholder: "John23",
    required: true,
    pattern: "^[A-Za-z0-9]{3,12}$",
    errorMsg:
      "Username should be 3-12 characters & should not include any special character!",
  },
  {
    id: 3,
    name: "name",
    lable: "Name",
    type: "text",
    placeholder: "John Smith",
    required: true,
    pattern: "^[A-Za-z]{1,20}$",
    errorMsg: "Name is required!",
  },
  {
    id: 4,
    name: "email",
    lable: "Email",
    type: "email",
    placeholder: "example@email.com",
    required: true,
    errorMsg: "Enter a valid email!",
  },
  {
    id: 5,
    name: "password",
    lable: "Password",
    type: "password",
    placeholder: "Password",
    required: true,
    pattern:
      "^(?=.*[0-9])(?=.*[A-Za-z])(?=.*[!@#$%^&*()_+])[A-Za-z0-9!@#$%^&*()_+]{6,20}$",
    errorMsg:
      "Password should be 6-20 characters and include at last 1 num, 1 letter, 1 special character!",
  },
  {
    id: 6,
    name: "address",
    lable: "Address",
    type: "text",
    placeholder: "Address",
    required: true,
    errorMsg: "Address is required!",
  },
];
const productInpDetails = [
  {
    id: 2,
    name: "title",
    lable: "Title",
    type: "text",
    placeholder: "Service title",
    required: true,
    errorMsg: "Title is required!",
  },
  // {
  //   id: 3,
  //   name: "description",
  //   lable: "Description",
  //   type: "textarea",
  //   placeholder: "Product description",
  //   required: true,
  //   errorMsg: "Description is required!",
  // },
  {
    id: 4,
    name: "imageUrl",
    lable: "ImageUrl",
    type: "text",
    placeholder: "Service Image URL",
    required: true,
    errorMsg: "Image URL is required!",
  },
  {
    id: 5,
    name: "thumbnailUrl",
    lable: "ThumbnailUrl",
    type: "text",
    placeholder: "Service Thumbnail URL",
    required: true,
    errorMsg: "Thumbnail is required!",
  },
];
const blogInputs = [
  {
    id: 1,
    name: "title",
    lable: "Title",
    type: "text",
    placeholder: "Blog title",
    required: true,
    errorMsg: "Title is required!",
  },
  {
    id: 2,
    name: "description",
    lable: "Description",
    type: "text",
    placeholder: "Blog description",
    required: true,
    errorMsg: "Description is required!",
  },
  {
    id: 3,
    name: "tags",
    lable: "Tags",
    type: "text",
    placeholder: "Travel, Communication",
    required: true,
    errorMsg: "Tag is required!",
  },
];
const taskInpDetails = [
  {
    id: 1,
    name: "platform",
    lable: "Platform",
    type: "select",
    options: ["YouTube", "Facebook", "Twitter", "Instagram"],
    required: true,
    errorMsg: "Please select a platform!",
  },
  {
    id: 2,
    name: "taskTitle",
    lable: "Task Title",
    type: "text",
    placeholder: "Enter task title",
    required: true,
    errorMsg: "Task Title is required!",
  },
  {
    id: 3,
    name: "taskPrice",
    lable: "Task Price",
    type: "number",
    placeholder: "Enter task price",
    required: true,
    errorMsg: "Task Price is required!",
  },
  {
    id: 4,
    name: "taskUrl",
    lable: "Task URL",
    type: "url",
    placeholder: "Enter task URL",
    required: true,
    errorMsg: "Task URL is required!",
  },
  {
    id: 5,
    name: "images",
    lable: "Images Upload",
    type: "file",
    accept: "image/*",
    multiple: true,
    required: false,
  },
  {
    id: 6,
    name: "description",
    lable: "Description",
    type: "textarea",
    placeholder: "Describe the task",
    required: true,
    errorMsg: "Description is required!",
  },
  {
    id: 7,
    name: "assignedTo",
    lable: "Assigned To",
    type: "select",
    options: ["Watcher", "Expert", "Creator"],
    required: true,
    errorMsg: "Please select an assignee!",
  },
];

function App() {
  // color state management using react context
  const { darkMode } = useContext(ColorContext);

  return (
    <div className={darkMode ? "App dark" : "App"}>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} /> {/* Unprotected route */}
          {/* Protected routes */}
          <Route element={<ProtectedRoute />}>
            <Route path="/" element={<Home />} />
            <Route path="users" element={<Lists type="user" />} />
            <Route path="users/:userId" element={<Detail />} />
            <Route
              path="users/addnew"
              element={
                <AddNewUser
                  inputs={userInpDetails}
                  titlee="Add New User"
                  type="USER"
                />
              }
            />
            <Route
              path="tasks/addnew"
              element={
                <AddNew
                  inputs={taskInpDetails}
                  titlee="Add New Task"
                  type="TASK"
                />
              }
            />

            <Route
              path="user/update"
              element={
                <UpdateUser
                  inputs={userInpDetails}
                  titlee="Update User"
                  type="USER"
                />
              }
            />

            <Route path="orders" element={<Orders />} />
            <Route path="addTask" element={<Task type="task" />} />
            <Route path="services" element={<Lists type="service" />} />
            <Route path="products/:productId" element={<Detail />} />
            <Route
              path="services/addnew"
              element={
                <AddNew
                  inputs={productInpDetails}
                  titlee="Add New Product"
                  type="SERVICE"
                />
              }
            />

            <Route path="blogs" element={<Blogs type="blog" />} />
            <Route path="blogs/:blogId" element={<BlogDetail />} />
            <Route
              path="blogs/addnew"
              element={
                <AddNew inputs={blogInputs} titlee="Add New Blog" type="BLOG" />
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
